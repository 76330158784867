/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Link, IconButton} from "theme-ui";
import {StaticImage} from "gatsby-plugin-image";
import AddListingButton from "../components/AddListingButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "About"), "\n", React.createElement(StaticImage, {
    alt: "image of Pedi",
    src: "../images/pedi.jpg",
    loading: "eager",
    width: 48,
    quality: 95,
    formats: ["auto", "webp", "avif"]
  }), "\n", React.createElement(_components.p, null, "Hi, my name is Pedi, I’m a mother of two teenagers with a big passion for\ncooking. I try to find the best deals for groceries all the time."), "\n", React.createElement(_components.p, null, "This gave me an idea: What if we share the best deals we find, while shopping,\nso that it’s easy for others to discover?"), "\n", React.createElement(_components.p, null, "This became Freshly Shopped."), "\n", React.createElement(_components.p, null, "Sounds good? Give it a try! It’s free! Join the Freshly Shopped community and\nstart saving and sharing today!"), "\n", React.createElement(AddListingButton), "\n", React.createElement("h1", {
    id: "contact"
  }, "Contact Us"), "\n", React.createElement(Link, {
    href: "https://www.instagram.com/freshly_shopped/"
  }, React.createElement(InstagramIcon, {
    sx: {
      fontSize: "80px"
    }
  })), "\n", React.createElement(Link, {
    href: "https://www.facebook.com/profile.php?id=100089105330328"
  }, React.createElement(FacebookIcon, {
    sx: {
      fontSize: "80px"
    }
  })), "\n", React.createElement("hr"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
